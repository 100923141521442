<template>
  <el-container>
    <el-header class="header1" height="32">
      <el-row type="flex" justify="start">
        <div class="back">
          <Icon iconName="arrow_left_white" />
          <Icon iconName="letter_a_white" />
        </div>
        <BreadCrumbs />
        <UserProfileButton /> </el-row
    ></el-header>
    <el-header class="header2" height="32"
      ><el-row type="flex" justify="start" align="center">
        <TIButton icon="plus_green" @onClick="createNewContract" :title="$t('Створити новий договір')" />
        <TIButton icon="edit_yellow" @onClick="editContract" :disabled="!selectedContractId" :title="$t('Редагувати')" />
        <TIButton icon="delete_red" @onClick="deleteContract" :disabled="!selectedContractId" :title="$t('Видалити')" />
        <TSeparator />
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="import_blue" :label="$t('Імпорт')" dropdown="true" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="import-contract-from-json"
              ><img class="svg" :src="require('../assets/icons/import_blue.svg')" />{{ $t("із JSON") }}</el-dropdown-item
            >
            <el-dropdown-item command="import-struct-from-json" :disabled="!selectedContractId"
              ><img :class="[!selectedContractId ? 'disabled' : '', 'svg']" :src="require('../assets/icons/import_blue.svg')" />{{
                $t("структур із JSON")
              }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click" @command="handleClick">
          <span class="el-dropdown-link">
            <TIButton icon="export_blue" :label="$t('Експорт')" dropdown="true" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="export-to-json" :disabled="!selectedContractId"
              ><img :class="[!selectedContractId ? 'disabled' : '', 'svg']" :src="require('../assets/icons/export_blue.svg')" />{{
                $t("в JSON")
              }}</el-dropdown-item
            >
            <el-dropdown-item command="export-struct-to-json" :disabled="!selectedContractId"
              ><img class="svg" :src="require('../assets/icons/export_blue.svg')" />{{ $t("структури в JSON") }}</el-dropdown-item
            >
            <el-dropdown-item divided command="export-legacy-to-json"
              ><img class="svg" :src="require('../assets/icons/export_blue.svg')" />{{ $t("договори в JSON (legacy)") }}</el-dropdown-item
            >
            <el-dropdown-item divided command="export-struct-to-xlsx"
              ><img class="svg" :src="require('../assets/icons/export_blue.svg')" />{{ $t("структури в XLS") }}</el-dropdown-item
            >
            <el-dropdown-item divided command="export-all-struct-to-xlsx"
              ><img class="svg" :src="require('../assets/icons/export_blue.svg')" />{{ $t("Усі структури в XLS") }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <TSeparator />
        <TIButton icon="copy_green" :label="$t('Створити копію')" @onClick="copyContract" :disabled="!selectedContractId" :title="$t('Створити копію')" />
        <TSeparator />
        <TIButton icon="bonus_matrix_red" :label="$t('Матриція бонусації')" @onClick="bonusMatrix" :title="$t('Матриція бонусації')" />
        <TSeparator />
        <TIButton icon="orgs_blue" :label="$t('Керування юр. особами')" @onClick="orgsAliases" :title="$t('Керування юр. особами')" />
        <TSeparator />
      </el-row>
    </el-header>
    <el-header class="header3" height="48"
      ><el-row type="flex" justify="start">
        <div style="padding-left: 19px">
          <el-input
            :placeholder="$t('Пошук')"
            style="width: 308px"
            prefix-icon="el-icon-search"
            clearable
            v-model="filterByName"
            ref="inputFilterName"
            @keydown.esc.native="resetFilterName"
          />
        </div>
        <div class="push" style="padding-right: 19px" v-bind:class="{ invalid: !filterByDateValid }">
          <span>{{ $t("Період") }}: </span>
          <el-date-picker v-model="filterByDateFrom" type="date" format="dd.MM.yyyy" ref="date1"> </el-date-picker>
          <span> - </span>
          <el-date-picker v-model="filterByDateTo" type="date" format="dd.MM.yyyy" ref="date2"> </el-date-picker>
        </div> </el-row
    ></el-header>
    <el-main
      ><ag-grid-vue
        ref="docsTable"
        style="width: 100%; height: 100%"
        class="ag-theme-alpine"
        :defaultColDef="defaultColDef"
        :columnDefs="columns"
        :headerHeight="32"
        :rowData="contracts"
        :context="context"
        :gridOptions="gridOptions"
        :frameworkComponents="frameworkComponents"
        :enableBrowserTooltips="true"
        rowSelection="single"
        @selection-changed="onGridSelectionChanged"
        @rowDoubleClicked="onGridDoubleClick"
        @sortChanged="onGridColumnsChange"
        @columnResized="onGridColumnsChange"
        @columnMoved="onGridColumnsChange"
        @displayedColumnsChanged="onGridColumnsChange"
        :overlayNoRowsTemplate="noRowsToShowTemplate"
        :modules="modules"
        :localeTextFunc="gridLocale"
        :sideBar="sideBar"
        :pivotMode="false"
        :getContextMenuItems="contextMenuItems"
      >
      </ag-grid-vue
    ></el-main>
    <EditContract @commit="contractCommitChanges" />
    <CopyContract @commit="contractCommitChanges" />
    <SelectFile @commit="contractImportData" bus-event="contracts.forms.select.file" />
    <ExportToXLS bus-event="contracts.forms.export.to.xls.file" />
    <ShowContractDetails bus-event="contracts.forms.details" />
    <OrgsAliases />
  </el-container>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import { bus } from "@/main";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";
import GridTotalBottomRow from "@/components/GridTotalBottomRow.js";
import UserProfileButton from "@/components/UserProfileButton";
import BreadCrumbs from "@/components/BreadCrumbs";
import { _ } from "vue-underscore";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

const Contracts = {
  name: "Contracts",
  components: {
    AgGridVue,
    TIButton,
    TSeparator,
    Icon,
    EditContract: () => import("@/components/forms/contracts/EditContract"),
    CopyContract: () => import("@/components/forms/contracts/CopyContract"),
    ShowContractDetails: () => import("@/components/forms/contracts/ShowContractDetails"),
    BreadCrumbs,
    UserProfileButton,
    SelectFile: () => import("@/components/forms/SelectFile"),
    ExportToXLS: () => import("@/components/forms/ExportToXLS"),
    OrgsAliases: () => import("@/components/forms/OrgsAliases"),
  },
  beforeMount() {
    var that = this;
    this.frameworkComponents = { GridCellWithIcons, GridTotalBottomRow };
    this.context = { componentParent: this };
    this.columns = [
      {
        headerName: $tt("Назва"),
        field: "name",
        width: 350,
        cellRenderer: "GridCellWithIcons",
        cellIcons: [
          {
            icon: "edit",
            iconClass: "icon-action",
            type: "fas el-icon-fa-",
            color: "green",
            title: $tt("Редагувати"),
            onClick: (params) => {
              this.editContract(params.data._id);
            },
            show: function(params) {
              return true;
            },
          },
          {
            icon: "eye",
            iconClass: "icon-action",
            type: "fas el-icon-fa-",
            color: "green",
            title: $tt("Дивитися"),
            onClick: (params) => {},
            show: function(params) {
              return false;
            },
          },
          {
            icon: "info-circle",
            type: "fas el-icon-fa-",
            color: "blue",
            iconClass: "icon-action",
            title: function(params) {
              return (
                $tt("Назва") +
                ": " +
                params.data.name +
                ", " +
                $tt("створено") +
                ": " +
                new Date(params.data.created.date).toLocaleDateString() +
                (params.data.updated ? ", " + $tt("змінено") + ": " + new Date(params.data.updated.date).toLocaleDateString() : "")
              );
            },
            onClick: (params) => {
              this.showContractDetails(params.data._id);
            },
          },
          {
            icon: "paperclip",
            type: "fas el-icon-fa-",
            color: "#800",
            iconClass: "icon-action",
            title: function(params) {
              return $tt("Додаток") + ": " + params.data.attachFile.srcName;
            },
            onClick: (params) => {
              this.downloadFile(params.data);
            },
            show: function(params) {
              return params.data.attachFile;
            },
          },
        ],
      },
      {
        headerName: $tt("Статус"),
        field: "status",
        width: "150px",
        flex: 1,
        filter: "agSetColumnFilter",
        valueGetter: function(params) {
          var date = new Date();
          if (Date.parse(params.data.beginDate) > Date.parse(date)) return $tt("Буде");
          else if (Date.parse(params.data.endDate) < Date.parse(date)) return $tt("Завершено");
          else return $tt("Активний");
        },
        cellRenderer: "GridCellWithIcons",
        cellIcons: [
          {
            icon: "dot-circle",
            type: "fas el-icon-fa-",
            color: function(params) {
              if (params.value == $tt("Завершено")) return "gray";
              if (params.value == $tt("Буде")) return "blue";
              if (params.value == $tt("Активний")) return "green";
            },
            iconClass: "icon-action",
            title: function(params) {
              return params.value;
            },
            onClick: (params) => {},
          },
        ],
      },
      {
        headerName: $tt("Замовник"),
        field: "customer",
        valueGetter: function(params) {
          if (params.data.customer) return `${params.data.customer.name}`;
          else return "";
        },
        width: "270px",
      },
      {
        headerName: $tt("Виконавець"),
        field: "executor",
        valueGetter: function(params) {
          if (params.data.executor) return `${params.data.executor.name}`;
          else return "";
        },

        width: "270px",
      },
      {
        headerName: $tt("Дата початку"),
        field: "beginDate",
        valueGetter: function(params) {
          return new Date(params.data.beginDate).toLocaleDateString();
        },
        width: "140px",
        filter: "agDateColumnFilter",
      },
      {
        headerName: $tt("Дата закінчення"),
        field: "endDate",
        valueGetter: function(params) {
          return new Date(params.data.endDate).toLocaleDateString();
        },
        width: "140px",
        filter: "agDateColumnFilter",
      },
      {
        headerName: $tt("Постачальники"),
        field: "providers",
        valueGetter: function(params) {
          return params.data._providersCount;
          //if (params.data._providersCount && params.data._providersCount > 0) return `${params.data._providersCount}`;
          //else return $tt("Постачальників не обрано");
        },
        cellClass: function(params) {
          return params.data._providersCount > 0 ? "ag-right-aligned-cell" : "grid-cell-red-text ag-right-aligned-cell";
        },

        width: "270px",
      },
      {
        headerName: $tt("Структури"),
        field: "structures",
        valueGetter: function(params) {
          return params.data._structuresCount;
          //if (params.data._structuresCount && params.data._structuresCount > 0) return `${params.data._structuresCount}`;
          //else return 0; //$tt("Структур не обрано");
        },
        cellClass: function(params) {
          return params.data._structuresCount > 0 ? "ag-right-aligned-cell" : "grid-cell-red-text ag-right-aligned-cell";
        },
        width: "170px",
      },
      {
        headerName: $tt("Товари"),
        field: "sku",
        valueGetter: function(params) {
          return params.data._skuCount;
          //if (params.data._skuCount && params.data._skuCount > 0) return `${params.data._skuCount}`;
          //else return 0; //$tt("Товарів не обрано");
        },
        cellClass: function(params) {
          return params.data._skuCount > 0 ? "ag-right-aligned-cell" : "grid-cell-red-text ag-right-aligned-cell";
        },
        width: "170px",
      },
      {
        headerName: $tt("Умови"),
        field: "restricts",
        valueGetter: function(params) {
          return params.data._restrictsCount;
          //if (params.data._restrictsCount && params.data._restrictsCount > 0) return `${params.data._restrictsCount}`;
          //else return 0; //$tt("Умов не задано");
        },
        cellClass: function(params) {
          return params.data._restrictsCount > 0 ? "ag-right-aligned-cell" : "grid-cell-red-text ag-right-aligned-cell";
        },
        width: "170px",
      },
      {
        headerName: $tt("План"),
        field: "planValue",
        width: "100px",
        type: "rightAligned",
      },
      {
        headerName: $tt("Валюта"),
        field: "currency",
        width: "90px",
      },
      {
        headerName: $tt("Курс"),
        field: "rate",
        width: "90px",
        type: "rightAligned",
      },
      {
        headerName: "Bitrix ID",
        field: "bitrixId",
        width: "90px",
        type: "rightAligned",
      },
    ].map((column) => _.extend(column, { headerTooltip: column.headerName }));
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.restoreGridSettings(this, this.gridOptions, "contracts", this.columns, $tt("Договори"));
    window.addEventListener("keydown", this.onKeyDownHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDownHandler);
  },
  data() {
    var that = this;
    return {
      modules: AllModules,
      selectedContractId: null,
      gridApi: null,
      frameworkComponents: null,
      context: null,
      noRowsToShowTemplate: `<div><img src="${require("../assets/icons/no_data.svg")}"></div>`,
      _filterByDateFrom: null,
      _filterByDateTo: null,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            {
              statusPanel: "GridTotalBottomRow",
              align: "left",
              items: [{ id: 1, label: $tt("Всього"), value: this.$store.getters["contracts/contractsTotalCount"] }],
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      filterByName: "",
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        suppressMenu: false,
        filter: "agSetColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns: null,
    };
  },
  computed: {
    contracts() {
      return this.$store.getters["contracts/contractsStoreFiltered"]({
        byName: this.filterByName,
        byDateFrom: this.filterByDateFrom,
        byDateTo: this.filterByDateTo,
      });
    },
    filterByDateValid() {
      if (this.filterByDateFrom && this.filterByDateTo) {
        if (Date.parse(this.filterByDateFrom) > Date.parse(this.filterByDateTo)) {
          return false;
        } else return true;
      } else return true;
    },
    filterByDateFrom: {
      get() {
        return this.$store.getters["main/getFilterByDateFrom"]();
      },
      set(value) {
        this.$store.dispatch("main/setFilterByDateFrom", value);
      },
    },
    filterByDateTo: {
      get() {
        return this.$store.getters["main/getFilterByDateTo"]();
      },
      set(value) {
        this.$store.dispatch("main/setFilterByDateTo", value);
      },
    },
  },
  watch: {
    filterByDateFrom: {
      handler(val) {
        if (val && this.filterByDateTo) {
          if (Date.parse(val) > Date.parse(this.filterByDateTo)) {
            this.$message.warning($tt("Помилка в фільтрі періодів"));
          }
        }
      },
    },
    filterByDateTo: {
      handler(val) {
        if (val && this.filterByDateFrom) {
          if (Date.parse(this.filterByDateFrom) > Date.parse(val)) {
            this.$message.warning($tt("Помилка в фільтрі періодів"));
          }
        }
      },
    },
  },

  methods: {
    resetFilterName(event) {
      this.$refs.inputFilterName.clear();
    },
    getContractById(id) {
      if (id) {
        return this.$store.getters["contracts/getContractById"](id);
      } else return null;
    },
    getSelectedContract() {
      if (this.selectedContractId) {
        return this.$store.getters["contracts/getContractById"](this.selectedContractId);
      } else return null;
    },
    wait(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },
    onButtonClick(target) {
      this.$message.success("click");
    },
    onGridDoubleClick(data) {
      //this.editContract();
      this.editContractRestricts();
    },
    onGridSelectionChanged() {
      var rows = this.gridApi.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedContractId = rows[0]._id;
      } else {
        this.selectedContractId = null;
      }
    },
    createNewContract() {
      bus.$emit("forms.contracts.edit.show", {
        title: $tt("Створити новий договір"),
        operation: "create",
        initModel: {
          name: "_test_contract_" + new Date().toLocaleDateString(),
          currency: "UAH",
          customer: this.$store.getters["main/userSettings"]("userSettings").defaultCustomer,
          executor: this.$store.getters["main/userSettings"]("userSettings").defaultExecutor,
          providers: [],
          structures: [],
          sku: [],
          beginDate: new Date(new Date().setHours(0, 0, 0, 0)),
          endDate: new Date(new Date(new Date().setHours(23, 59, 59, 0)).setFullYear(new Date().getFullYear() + 1)),
        },
      });
    },
    editContract(id) {
      var contract = _.isObject(id) ? this.getSelectedContract() : this.getContractById(id);
      if (contract) {
        bus.$emit("forms.contracts.edit.show", { title: $tt("Редагувати"), operation: "update", initModel: contract });
      }
    },
    showContractDetails(id) {
      var contract = _.isObject(id) ? this.getSelectedContract() : this.getContractById(id);
      if (contract) {
        bus.$emit("contracts.forms.details.show", { title: $tt("Інформація про умови договору"), contract: contract });
      }
    },
    downloadFile(contract) {
      if (contract) {
        this.$store.dispatch("main/setGlobalLoading", $tt("Завантаження файлу..."));
        this.$store
          .dispatch("contracts/downloadAttachedFile", { contractId: contract._id, fileId: contract.attachFile.id, fileName: contract.attachFile.srcName })
          .finally(() => {
            this.$store.dispatch("main/setGlobalLoading");
          })
          .then(() => {})
          .catch((err) => {
            globalErrorMessage($tt("Помилка"), err);
          });
      }
    },
    editContractRestricts(id) {
      if (id) {
        this.$router.push("/contract/" + id);
      } else if (this.selectedContractId) {
        this.$router.push("/contract/" + this.selectedContractId);
      } else {
        this.$message.warning($tt("Договір не обрано"));
      }
    },
    deleteContract(id) {
      var contract = _.isObject(id) ? this.getSelectedContract() : this.getContractById(id);
      if (contract) {
        this.$confirm($tt("Ви дійсно хочете видалити договір {name}?", { name: contract.name }), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            this.$store
              .dispatch("contracts/del", contract._id)
              .then(() => {
                this.$message.success($tt("Договір видалено"));
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка видалення"), err);
              });
          })
          .catch((err) => {});
      }
    },
    copyContract() {
      var contract = this.getSelectedContract();
      if (contract) {
        bus.$emit("forms.contracts.copy.show", {
          title: $tt("Створення копії"),
          initModel: { name: "_" + contract.name, beginDate: contract.beginDate, endDate: contract.endDate },
        });
      }
    },
    bonusMatrix() {
      this.$router.push("/bonusMatrix");
    },
    orgsAliases() {
      bus.$emit("forms.orgs.aliases.show", {});
    },
    contractCommitChanges(data) {
      if (data.operation == "create") {
        var loading = this.$loading({ target: "#EditContractDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("contracts/create", data.model)
          .finally(() => {
            loading && loading.close();
          })
          .then((newData) => {
            bus.$emit("forms.contracts.edit.hide");
            this.$message.success($tt("Договір створено!"));
            this.editContractRestricts(newData.data._id);
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка створення"), err.data.msg);
          });
      } else if (data.operation == "update") {
        var loading = this.$loading({ target: "#EditContractDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("contracts/update", data.model)
          .finally(() => {
            loading && loading.close();
          })
          .then(() => {
            bus.$emit("forms.contracts.edit.hide");
            var rowNode = this.gridApi.getRowNode(data.model._id);
            rowNode.setData(data.model);
            this.gridApi.redrawRows({ rowNodes: [rowNode] });
            this.$message.success($tt("Договір оновлено!"));
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка оновлення"), err);
          });
      } else if (data.operation == "copy") {
        var loading = this.$loading({ target: "#CopyContractDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("contracts/copy", {
            copyContractId: this.selectedContractId,
            newName: data.model.name,
            beginDate: data.model.beginDate,
            endDate: data.model.endDate,
          })
          .finally(() => {
            loading && loading.close();
          })
          .then((newData) => {
            bus.$emit("forms.contracts.copy.hide");
            this.$message.success($tt("Копію договору створено!"));
            var node = this.gridApi.getRowNode(newData.data._id);
            if (node) node.setSelected(true);
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка створення"), err);
          });
      }
    },
    contractImportData(data) {
      var that = this;
      bus.$emit("contracts.forms.select.file.clear");
      if (data.operation == "contract.import") {
        // import contract
        that
          .readJSONFile(data.file)
          .then((result) => {
            if (result && result.dataType && result.dataType == "contract" && result.data) {
              result.data.name = result.data.name + " (imported " + new Date().toLocaleDateString() + " at " + new Date().toLocaleTimeString() + ")";
              this.$store.dispatch("main/setGlobalLoading", $tt("Збереження договору..."));
              that.$store
                .dispatch("contracts/create", result.data)
                .finally(() => {
                  that.$store.dispatch("main/setGlobalLoading");
                })
                .then((newData) => {
                  that.$message.success($tt("Договір створено!"));
                  var node = that.gridApi.getRowNode(newData.data._id);
                  if (node) node.setSelected(true);
                })
                .catch((err) => {
                  globalErrorMessage($tt("Помилка створення"), err);
                });
            } else {
              globalErrorMessage($tt("Помилка імпорту"), $tt("Помилка в структурі JSON файлу"));
            }
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка імпорту"), err);
          });
      } else if (data.operation == "contract.structure.import") {
        // import structures
        var contract = this.getSelectedContract();
        this.$store.dispatch("main/setGlobalLoading", $tt("Імпорт даних..."));
        this.$store
          .dispatch("contracts/loadOne", { id: contract._id })
          .finally(() => {
            this.$store.dispatch("main/setGlobalLoading");
          })
          .then((contractFull) => {
            that
              .readJSONFile(data.file)
              .then((result) => {
                if (result && result.dataType && result.dataType == "structures" && result.data) {
                  contractFull.data.data.structures = result.data;
                  this.$store.dispatch("main/setGlobalLoading", $tt("Збереження договору..."));
                  that.$store
                    .dispatch("contracts/update", contractFull.data.data)
                    .finally(() => {
                      this.$store.dispatch("main/setGlobalLoading");
                    })
                    .then(() => {
                      var rowNode = this.gridApi.getRowNode(contractFull.data.data._id);
                      rowNode.setData(contractFull.data.data);
                      this.$message.success($tt("Договір оновлено!"));
                    })
                    .catch((err) => {
                      globalErrorMessage($tt("Помилка збереження"), err);
                    });
                } else {
                  globalErrorMessage($tt("Помилка імпорту"), $tt("Помилка в структурі JSON файлу"));
                }
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка імпорту"), err);
              });
          });
      }
    },
    exportContractToJSON(contract) {
      if (contract) {
        var loading = this.$loading({ target: "body", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("contracts/loadOne", { id: contract._id })
          .finally(() => {
            loading && loading.close();
          })
          .then((data) => {
            var dt = new Date()
              .toLocaleString()
              .split(",")
              .join("-");
            this.saveDataToJSON({ dataType: "contract", data: data.data.data }, data.data.data.name + "_" + dt + ".json");
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка експорту"), err);
          });
      }
    },
    exportContractStructuresToJSON(contract) {
      if (contract) {
        var loading = this.$loading({ target: "body", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("contracts/loadOne", { id: contract._id })
          .finally(() => {
            loading && loading.close();
          })
          .then((data) => {
            var dt = new Date()
              .toLocaleString()
              .split(",")
              .join("-");
            this.saveDataToJSON({ dataType: "structures", data: data.data.data.structures }, data.data.data.name + "_structures_" + dt + ".json");
          })
          .catch((err) => {
            globalErrorMessage($tt("Помилка експорту"), err);
          });
      }
    },
    exportAllContractStructuresToXLSX() {
      var loading = this.$loading({ target: "body", text: $tt("Будь ласка, зачекайте...") });
      this.$store
        .dispatch("contracts/exportAllStructToXLSX")
        .finally(() => {
          loading && loading.close();
        })
        .then((data) => {
          this.saveDataToXLS(data.data, "all_structures_" + new Date().toLocaleDateString("ru-RU") + ".xlsx");
          //this.$message.success("Файл створено! " + data.fileId);
          //this.$store.dispatch("main/downloadFile", { fileId: data.fileId, fileName: data.fileName }).catch((err) => {
          //  globalErrorMessage($tt("Помилка експорту"), err);
          //});
          // this.$confirm($tt("Файл створено! Натисніть 'Скачати' для завантаження файлу."), $tt("Експорт"), {
          //   confirmButtonText: $tt("Скачати"),
          //   cancelButtonText: $tt("Відміна"),
          //   type: "success",
          // })
          //   .then(() => {
          //     this.$store.dispatch("main/downloadFile", { fileId: data.fileId, fileName: data.fileName }).catch((err) => {
          //       globalErrorMessage($tt("Помилка експорту"), err);
          //     });
          //   })
          //   .catch((err) => {});
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка експорту"), err);
        });
    },
    _exportContractStructuresToXLSX(contractsArray, name) {
      var loading = this.$loading({ target: "body", text: $tt("Будь ласка, зачекайте...") });
      this.$store
        .dispatch("contracts/exportStructToXLSX", contractsArray)
        .finally(() => {
          loading && loading.close();
        })
        .then((data) => {
          this.saveDataToXLS(data.data, "structures_" + new Date().toLocaleDateString("ru-RU") + ".xlsx");
          // this.$message.success("Файл створено! " + data.fileId);
          // this.$store.dispatch("main/downloadFile", { fileId: data.fileId, fileName: data.fileName }).catch((err) => {
          //   globalErrorMessage($tt("Помилка експорту"), err);
          // });
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка експорту"), err);
        });
    },
    _exportLegacyContractsToJSON(contractsArray, name) {
      var loading = this.$loading({ target: "body", text: $tt("Будь ласка, зачекайте...") });
      this.$store
        .dispatch("contracts/loadContractsLegacy", contractsArray)
        .finally(() => {
          loading && loading.close();
        })
        .then((data) => {
          var dt = new Date()
            .toLocaleString()
            .split(",")
            .join("-");
          if (data && data.data) {
            this.saveDataToJSON(data.data, name + "_" + dt + ".json");
          } else {
            this.saveDataToJSON({}, name + "_" + dt + ".json");
          }
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка експорту"), err);
        });
    },
    exportContractStructuresToXLSX(contract) {
      if (!contract) {
        this.$confirm($tt("Не обрано жодного догорвору! Сформувати звіт для всіх доступних договорів?"), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            var contractsArray = this.contracts.map((c) => c._id);
            this._exportContractStructuresToXLSX(contractsArray, "filtered");
          })
          .catch((err) => {});
      } else {
        var contractsArray = [contract._id];
        this._exportContractStructuresToXLSX(contractsArray, contract.name);
      }
    },
    exportLegacyContractsToJSON(contract) {
      if (!contract) {
        this.$confirm($tt("Не обрано жодного догорвору! Сформувати звіт для всіх доступних договорів?"), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            var contractsArray = this.contracts.map((c) => c._id);
            this._exportLegacyContractsToJSON(contractsArray, "filtered");
          })
          .catch((err) => {});
      } else {
        var contractsArray = [contract._id];
        this._exportLegacyContractsToJSON(contractsArray, contract.name);
      }
    },

    importContractStructuresFromJSON(contract) {
      if (contract) {
        bus.$emit("contracts.forms.select.file.show", {
          title: $tt("Імпорт структур"),
          operation: "contract.structure.import",
          initModel: {},
        });
      }
    },
    importContractFromJSON() {
      bus.$emit("contracts.forms.select.file.show", {
        title: $tt("Імпорт договору"),
        operation: "contract.import",
        initModel: {},
      });
    },
    handleClick(command) {
      switch (command) {
        case "export-to-json": {
          this.exportContractToJSON(this.getSelectedContract());
          break;
        }
        case "export-struct-to-json": {
          this.exportContractStructuresToJSON(this.getSelectedContract());
          break;
        }
        case "export-all-struct-to-xlsx": {
          this.exportAllContractStructuresToXLSX();
          break;
        }
        case "export-struct-to-xlsx": {
          this.exportContractStructuresToXLSX(this.getSelectedContract());
          break;
        }
        case "export-legacy-to-json": {
          this.exportLegacyContractsToJSON(this.getSelectedContract());
          break;
        }

        case "import-contract-from-json": {
          this.importContractFromJSON();
          break;
        }
        case "import-struct-from-json": {
          this.importContractStructuresFromJSON(this.getSelectedContract());
          break;
        }

        default:
          break;
      }
    },
    onGridColumnsChange() {
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        clearTimeout(this.changeColumnsTimer);
        this.saveGridState(this.gridOptions, "contracts");
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    getContextIcon(icon) {
      return `<img class="svg" src="${require(`../assets/icons/${icon}.svg`)}" />`;
    },
    contextMenuItems(params) {
      var contextMenu = [];
      var that = this;
      contextMenu.push(
        {
          name: $tt("Фільтр для кожного стовпчика"),
          shortcut: "Ctrl + F",
          icon: that.getContextIcon("apply_for_column_green"),
          action() {
            that.toggleFloatingFilter(that.gridOptions, that.columns);
          },
        },
        "separator"
      );
      contextMenu.push("export");
      return contextMenu;
    },
    onKeyDownHandler(e) {
      if (e.ctrlKey) {
        if (e.key.toLowerCase() == "f" || e.key.toLowerCase() == "а") {
          this.toggleFloatingFilter(this.gridOptions, this.columns);
          e.preventDefault();
          return false;
        }
      }
    },
  },
};
export default Contracts;
</script>

<style lang="scss" scope>
.el-main {
  padding: 0;
  background: #fff;
  padding: 0px !important;
  height: calc(100vh - 114px);
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 136px;
}

.invalid {
  background: rgb(253, 171, 171);
  transition: all 1s;
}
</style>
