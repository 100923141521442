import Vue from "vue";

export default Vue.extend({
  template: `<div class="ag-status-name-value ag-status-panel">  
                <div v-for="(item,index) in this.items" :key="index" style="padding-right:8px;">
                  <span >{{compLabel(item)}}</span>:&nbsp<span class="ag-status-name-value-value">{{compValue(item)}}</span>
                </div>
             </div>`,
  data: function() {
    return {
      items: [],
    };
  },
  beforeMount() {
    this.items = this.params.api.gridOptionsWrapper.gridOptions.statusBar.statusPanels.find((item) => item.statusPanel == "GridTotalBottomRow").items;
  },
  methods: {
    compLabel(item) {
      if (typeof item.label == "function") return item.label(item);
      else return item.label;
    },
    compValue(item) {
      if (typeof item.value == "function") return item.value(item);
      else return item.value;
    },
  },
});
